<template>
  <section v-if="!slice.primary.hide_section" class="home__feature large-pad">
    <div class="row">
      <div class="home__feature__left column small-12 medium-10 large-8 xlarge-4 xxlarge-3">
        <h2 class="h5">{{ slice.primary.title | getTitle }}</h2>
        <h3 v-if="slice.primary.subtitle" class="h1">
          <prismic-rich-text :field="slice.primary.subtitle" />
        </h3>
        <prismic-rich-text v-if="slice.primary.description" :field="slice.primary.description" />
        <!-- <Tooltip direction="right">
          <template v-slot:trigger>
            Gee women
          </template>
          <template v-slot:content>
            <div>
              <LazyImage
                src="https://via.placeholder.com/200x150"
                retinaSrc=""
                width="200"
                height="150"
                alt="Alt text goes here"
              />
            </div>
            <div>
              <p>The team behind Gee – <a href="#">Miriam</a>, <a href="#">Natalie</a>, <a href="#">Stephanie</a>, & <a href="#">Celene</a> - keep the shelves brimming with tried-&-true beauty favorites. We’re a family of working women set out to educate and help every woman get results.</p>
              <ArrowLink to="#" text="Learn More" />
            </div>
          </template>
        </Tooltip> -->
        <Button v-if="slice.primary.button_text" :link="slice.primary.button_link" :text="slice.primary.button_text" />
        <span v-if="slice.primary.caption || slice.primary.caption_link_text" class="caption">
          {{ slice.primary.caption ? slice.primary.caption : '' }} <ArrowLink v-if="slice.primary.caption_link_text" :link="slice.primary.caption_link" size="small" :text="slice.primary.caption_link_text" />
        </span>
      </div>
      <div class="home__feature__right column small-12 xlarge-8 xxlarge-9">
        <div class="stacked-images">
          <div class="back-image" :class="{ 'has-mobile-image' : slice.primary.large_image_mobile.url }">
            <PrismicLazyImage
              :image="slice.primary.large_image"
              width="1165"
              height="750"
              class="desktop-image"
            />
            <PrismicLazyImage
              v-if="slice.primary.large_image_mobile.url"
              :image="slice.primary.large_image_mobile"
              width="750"
              height="483"
              class="mobile-image"
            />
          </div>
          <div class="front-image product transparent">
            <PrismicLazyImage
              :image="slice.primary.small_image"
              width="425"
            />
          </div>
          <span v-if="slice.primary.caption || slice.primary.caption_link_text" class="caption">
            {{ slice.primary.caption ? slice.primary.caption : '' }} <ArrowLink v-if="slice.primary.caption_link_text" :link="slice.primary.caption_link" size="small" :text="slice.primary.caption_link_text" />
          </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import LazyImage from '@/components/global/LazyImage';
  import PrismicLazyImage from '@/components/prismic/PrismicLazyImage';
  import Button from '@/components/global/Button';
  import ArrowLink from '@/components/global/ArrowLink';
  import Tooltip from '@/components/global/Tooltip';

  export default {
    name: 'HomepageFeature',

    components: {
      LazyImage,
      Tooltip,
      PrismicLazyImage,
      Button,
      ArrowLink
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    }
  }
</script>
